<!-- 选择小区 -->
<template>
  <div>
    <ax-table
      ref="roleTable3"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.queryAll"
      :dataSourceParams="dataSourceParams"
      :sqlParams="sqlParams"
      :clickable="false"
      :rowSelection="{
        onChange: onSelectChange,
        type: radio,
      }"
    >
    </ax-table>
    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 20px" @click="close"> 取消 </a-button>
    </div>
  </div>
</template>
        
    <script>
import api from "../api";
const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "key",
    options: { placeholder: "请输入关键字", showTime: true },
    col: { span: 8 },
  },
];
export default {
  data() {
    return {
      api,
      radio: "radio", //单选
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      columns: this.$ax.tools.initColumn([
        {
          title: "小区名称",
          dataIndex: "gridName",
          maxWidth: 120,
        },
        {
          title: "所属区域",
          dataIndex: "address",
          ellipsis: true,
          maxWidth: 120,
        },
      ]),
      dataSourceParams: {},
      sqlParams: {},
      rowSelection: {},
    };
  },
  watch: {
    rowSelection(e) {
      // console.log(e);
    },
  },
  created() {},
  methods: {
    onSubmit() {
      this.$emit("close");
    },
    //关闭弹窗
    close() {
      this.$emit("close");
    },
    //多选上传对象
    onSelectChange(selectedRowKeys, selectionRows) {
      this.$emit("selection-change", selectionRows);
      // console.log(selectionRows);
    },
  },
};
</script>
        
    <style lang="scss" scoped>
</style>
        