<!-- 编辑楼栋信息 -->
<template>
  <div class="addld">
    <!-- 基本信息 -->
    <div class="header-title">
      <ax-form ref="formBox" :formBuilder="formBuilder" @change="onFormChange">
        <!-- 小区网格 -->
        <div slot="gridName" slot-scope="{ record }">
          <div v-if="level === '1' || !level">
            <div class="gridname" @click="showPopup">
              <span
                v-decorator="[
                  record.model,
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择小区',
                      },
                    ],
                  },
                ]"
                class="gridspan"
                :style="{ display: name ? 'none' : 'block' }"
              >
                请选择小区
              </span>
              <span v-if="name">{{ name }}</span>
            </div>
            <el-dialog
              title="选择小区"
              width="800px"
              :visible.sync="modalVisible"
              :z-index="12"
              :destroy-on-close="true"
              :close-on-click-modal="false"
            >
              <housing
                ref="position"
                @close="modalVisible = false"
                @selection-change="handleSelectiononeChange"
              />
            </el-dialog>
          </div>
          <div v-else>
            <div class="gridname2">
              <span
                v-decorator="['gridName', { rules: [{ required: true }] }]"
                >{{ gridName }}</span
              >
            </div>
          </div>
        </div>
        <!-- 地图标绘按钮 -->
        <div slot="position" style="display: flex">
          <ax-button type="#faad14" @click="dialogVisible = true"
            >地图标绘</ax-button
          >
          <el-dialog
            title="地图标绘"
            width="1000px"
            :visible.sync="dialogVisible"
            :z-index="12"
            :destroy-on-close="true"
            :close-on-click-modal="false"
          >
            <position
              ref="position"
              @close="dialogVisible = false"
              @locationUpdated="handleLocationUpdate"
            />
          </el-dialog>
        </div>
        <!-- 楼栋经纬度 -->
        <div slot="trapeze" style="display: flex">
          <a-input-group>
            <a-row :gutter="24" style="display: flex; align-items: center">
              <a-col :span="11">
                <a-input
                  placeholder="请完成地图标绘"
                  v-model="latitude"
                  disabled
                />
              </a-col>
              <a-col :span="2">
                <div>-</div>
              </a-col>
              <a-col :span="11">
                <a-input
                  placeholder="请完成地图标绘"
                  v-model="longitude"
                  disabled
                />
              </a-col>
            </a-row>
          </a-input-group>
        </div>
        <div class="title" slot="title1">&nbsp;基本信息</div>
        <div class="title" slot="title2">&nbsp;其他信息</div>
      </ax-form>
    </div>

    <div class="footer">
      <div class="btn">
        <a-button type="primary" @click="submit"> 保存 </a-button>
        <a-button @click="closeDialog">取消</a-button>
      </div>
    </div>
  </div>
</template>
  <script>
import api from "../../api";
import position from "../../Common/position.vue";
import housing from "../../Common/housing.vue";

const options = [
  { label: "单元楼", value: "0" },
  { label: "筒子楼", value: "1" },
  { label: "别墅", value: "2" },
  { label: "自建楼", value: "3" },
  { label: "平房", value: "4" },
  { label: "其他", value: "5" },
];
const formList = [
  {
    label: "",
    type: "",
    model: "title1",
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "小区名称",
    type: "",
    model: "gridName",
    options: { placeholder: "山水西苑" },
    col: { span: 24 },
    formItem: { colon: false },
  },
  {
    label: "房屋类型",
    type: "radio",
    model: "houseType",
    options: { options },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请选择房屋类型",
      },
    ],
  },
  {
    label: "楼栋号",
    type: "",
    model: "blockCode",
    options: { placeholder: "请输入楼栋号", showTime: true },
    col: { span: 12 },
    rules: [
      {
        required: true,
        disabled: true,
        pattern: /^[1-9A-Za-z]\w{0,5}$/,
        message: "请输入正整数或字母",
      },
    ],
  },
  {
    label: "单元数",
    type: "",
    model: "unitNumber",
    options: { showTime: true, placeholder: "请输入单元数", maxLength: 3 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        disabled: true,
        pattern: /^[1-9]\d{0,2}$/,
        message: "请输入正整数",
      },
    ],
  },
  {
    label: "",
    type: "",
    model: "title2",
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "地图位置",
    type: "",
    model: "position",
    options: { showTime: true },
    col: { span: 12 },
  },
  {
    label: "楼栋经纬度",
    type: "",
    model: "trapeze",
    options: { showTime: true },
    col: { span: 12 },
  },
  {
    label: "楼长姓名",
    type: "",
    model: "managerName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "楼长姓名不能为空",
      },
    ],
  },
  {
    label: "楼长电话",
    type: "",
    model: "managerPhone",
    options: { showTime: true, placeholder: "请输入", maxLength: 11 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请填写正确格式的电话号码",
      },
    ],
  },
];
export default {
  components: { position, housing },
  data() {
    return {
      api,
      gridName: "",
      longitude: "",
      latitude: "",
      name: "",
      gridId: "",
      level: "",
      block: "",
      unit: "",
      customSelectionRows: [],
      modalVisible: false,
      dialogVisible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  props: ["id"],
  computed: {},
  created() {},
  methods: {
    //查看
    async getData() {
      try {
        const res = await api.getById2(this.id);
        if (res && res.data) {
          this.$refs.formBox.setFieldsValue(res.data); // Assign values
          this.gridName = res.data.cellName;
          this.$refs.formBox.setFieldsValue({ gridName: this.gridName }); // 设置gridName的值
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    //ax-from的change事件
    onFormChange(e, type) {
      if (type === "blockCode") {
        this.block = e;
        console.log("楼栋号", this.block);
      } else if (type === "unitNumber") {
        this.unit = e;
        console.log("单元数", this.unit);
      }
    },
    //打开小区弹窗
    showPopup() {
      this.modalVisible = true; // 点击输入框时显示弹窗
    },
    //小区名称
    handleSelectiononeChange(selectionRows) {
      this.customSelectionRows = selectionRows;
      this.customSelectionRows.forEach((obj) => {
        if (obj.gridName) {
          this.name = obj.gridName;
          this.$nextTick(() => {
            this.$refs.formBox.setFieldsValue({ gridName: this.name });
          });
          console.log(this.name);
        }
        if (obj.id) {
          this.cellId = obj.id;
          console.log(this.cellId);
        }
      });
    },
    // 接收并处理传递过来的经纬度值
    handleLocationUpdate(locationData) {
      console.log("收到的经度值:", locationData.lng);
      this.latitude = locationData.lng;
      console.log("收到的纬度值:", locationData.lat);
      this.longitude = locationData.lat;
    },
    async submit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.id = this.id;
        value.cellId = this.cellId;
        value.cellName = this.gridName || this.name;
        this.saveType = true;
        try {
          const editRes = await api.addunit(value);
          if (editRes.status === 200) {
            this.$message.success("编辑成功");
            this.saveType = false;
            this.closeDialog(); // 在所有操作完成后关闭弹窗
            this.refreshTable();
          } else {
            this.$message.error("编辑失败");
            this.saveType = true;
          }
        } catch (error) {
          this.$message.error("编辑失败");
          this.saveType = false; // 捕获错误时设置为 false
        }
      });
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
  <style  lang="less" scoped>
.addld {
  width: 100%;
  height: 100%;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
.header {
  width: 100%;
  // height: 0.15rem;
  background: rgba(188, 204, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.btn {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.btn .ant-btn {
  margin: 0 10px; /* 在按钮的左右分别添加 10px 的间距 */
}
.title {
  border-left: 4px solid #556bff;
  font-size: 16px;
  font-weight: bold;
  color: black;
  line-height: 100%;
  margin-bottom: 15px;
}

.header-title {
  margin-bottom: 2%;
}
.gridname {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  .gridspan {
    position: absolute;
    left: 11px;
    color: #bfbfbf;
    pointer-events: none;
  }
}
.gridname2 {
  position: relative;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
}
</style>